import type { ReactElement } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import type { URLManager as URLManagerSchema } from '@amplience/content-types/typings/h-url-manager';
import { createAppointmentAmplienceComponent } from '@storefront/lib-appointments/components/appointmentsAmplienceComponents.utils';
import { createContentAmplienceComponent } from '@storefront/lib-content/components/contentAmplienceComponents.utils';
import type { BreadcrumbDataType } from '@storefront/lib-content/components/contentBreadcrumb';
import { createGlobalAmplienceComponent } from '@storefront/lib-global/globalComponents/globalAmplienceComponents.utils';
import type { HandlebarTemplateProps } from '@storefront/lib-global/globalComponents/handlebarTemplate';
import type { AmplienceContext } from '@storefront/lib-global/types/amplience';
import { renderAmplienceSlotComponents } from '@storefront/lib-global/utils/amplienceUtils';
import { createPDPAmplienceComponent } from '@storefront/lib-products/product-detail/components/pdpAmplienceComponents.utils';

/* eslint-disable promise/prefer-await-to-then */
const HTMLContent = dynamic(() =>
  import('@storefront/lib-content/components/htmlContentComponent/HTMLContent').then((mod) => mod.HTMLContent),
);

const HygienePageLayout = dynamic(() =>
  import('@storefront/lib-content/components/hygienePages/HygienePageLayout').then((mod) => mod.HygienePageLayout),
);

const ContentBreadcrumb = dynamic(() =>
  import('@storefront/lib-content/components/contentBreadcrumb/ContentBreadcrumb').then((mod) => mod.ContentBreadcrumb),
);
/* eslint-enable promise/prefer-await-to-then */

export type URLManagerProps = URLManagerSchema & {
  context: AmplienceContext;
  breadcrumbData: BreadcrumbDataType[];
  handlebarComponents: HandlebarTemplateProps[] | null;
};

export type ContentProps = {
  content: URLManagerProps['content'];
  context: URLManagerProps['context'];
  locale: string;
  breadcrumbData: BreadcrumbDataType[];
  handlebarComponents: HandlebarTemplateProps[] | null;
};

function Content({ content, breadcrumbData, handlebarComponents, locale }: ContentProps): ReactElement | null {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const contents = content as unknown as any;

  if (!content) {
    return null;
  }

  const isHygienePageLayout = contents[0]._meta.schema === 'https://clarks.com/slot/s-hygiene-page-layout.json';
  const isHtmlLayout = contents[0]._meta.schema === 'https://clarks.com/slot/s-html-layout.json';

  return (
    <>
      {breadcrumbData?.length ? <ContentBreadcrumb breadcrumbData={breadcrumbData} /> : null}
      {isHtmlLayout ? <HTMLContent {...contents[0]} /> : null}
      <div>
        {!isHygienePageLayout ? (
          contents[0].slotContent &&
          renderAmplienceSlotComponents(
            contents[0].slotContent.pageContent,
            {
              ...createGlobalAmplienceComponent(contents[0].slotContent.pageContent),
              ...createPDPAmplienceComponent(contents[0].slotContent.pageContent),
              ...createContentAmplienceComponent(contents[0].slotContent.pageContent),
              ...createAppointmentAmplienceComponent(contents[0].slotContent.pageContent),
            },
            handlebarComponents,
            locale,
          )
        ) : (
          <HygienePageLayout {...contents[0]} handlebarComponents={handlebarComponents} />
        )}
      </div>
    </>
  );
}
export function URLManager({ content, context, breadcrumbData, handlebarComponents }: URLManagerProps): ReactElement {
  const { locale } = useRouter();

  return (
    <Content
      content={content}
      context={context}
      locale={locale || 'en'}
      breadcrumbData={breadcrumbData}
      handlebarComponents={handlebarComponents}
    />
  );
}
